/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

interface Props {
  className: any;
}

export const Footer = ({ className }: Props): JSX.Element => {
  return (
    <div className={`footer ${className}`}>
      <p className="element-ATC-analytics">© 2024 ATC Analytics&nbsp;&nbsp;|&nbsp;&nbsp;All Rights Reserved</p>
      <div className="group">
        <div className="privacy-policy-terms-wrapper">
          {/* <div className="privacy-policy-terms">
            Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms of Use&nbsp;&nbsp;|&nbsp;&nbsp;Contact
          </div> */}
        </div>
      </div>
    </div>
  );
};

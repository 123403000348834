/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Navigation1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`navigation-1 ${className}`}
      fill="none"
      height="66"
      viewBox="0 0 1440 66"
      width="1440"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" filter="url(#filter0_d_46_592)">
        <rect className="rect" fill="white" height="62" width="1440" />
        <g className="g" clipPath="url(#clip0_46_592)">
          <path
            className="path"
            d="M45.4736 20.0792C45.4736 20.0792 45.8399 17.8029 44.8816 16.7579C44.1741 15.9849 43.3563 16 43.3563 16H41.4346V20.0867L45.4736 20.0766V20.0792Z"
            fill="url(#paint0_linear_46_592)"
          />
          <path
            className="path"
            d="M39.7387 35.5496C37.1372 35.5496 34.6938 34.5324 32.8524 32.6867C31.0135 30.841 30 28.386 30 25.7748C30 20.3838 34.3676 16 39.7387 16H40.4311V20.0892H39.7387C36.6179 20.0892 34.0791 22.6424 34.0791 25.7748C34.0791 27.2932 34.6687 28.7183 35.7374 29.791C36.8061 30.8637 38.226 31.4554 39.7387 31.4554C42.8595 31.4554 45.3983 28.9072 45.3983 25.7748V25.0799H49.4775V25.7748C49.4775 28.386 48.464 30.841 46.6251 32.6867C44.7862 34.5324 42.3402 35.5496 39.7387 35.5496Z"
            fill="#939598"
          />
          <path
            className="path"
            d="M49.4774 16V24.0752L45.3933 24.0576C45.3933 24.0576 45.4084 19.223 45.4084 19.0468C45.4084 15.9849 42.995 16 42.995 16H49.4774Z"
            fill="url(#paint1_linear_46_592)"
          />
          <path
            className="path"
            d="M81.1522 43.7734H85.3894V29.1414H89.9326C90.6651 29.1414 91.4253 28.9299 91.8568 28.104C92.4588 26.9558 93.0108 25.1907 93.0108 25.1907H76.3305C75.5979 25.1907 74.8378 25.4022 74.4063 26.2281C73.8042 27.3763 73.2523 29.1414 73.2523 29.1414H81.1547L81.1522 43.7734Z"
            fill="#F3703A"
          />
          <path
            className="path"
            d="M104.232 44C97.1175 44 94.5661 38.8961 94.5411 34.5248C94.526 31.8256 95.5144 29.2723 97.2505 27.5198C98.984 25.7698 101.397 24.8457 104.23 24.8457C106.771 24.8457 110.165 26.2079 112.019 28.0435L109.237 29.3302C108.301 29.8112 107.734 30.0025 107.002 29.5997C106.184 29.1489 105.236 28.9097 104.23 28.9097C102.569 28.9097 101.227 29.4158 100.238 30.418C99.2373 31.4302 98.6729 32.9259 98.6854 34.5223C98.708 37.1662 100.176 39.9914 104.23 39.9914C105.394 39.9914 106.593 39.6112 107.544 38.964C108.101 38.5864 108.77 38.4101 109.44 38.4932L112.997 38.9338C112.997 38.9338 112.561 39.8756 111.171 41.2705C109.325 43.1238 106.967 44 104.23 44H104.232Z"
            fill="#F3703A"
          />
          <path
            className="path"
            d="M65.6259 25.1982H61.4138L53.1125 43.7759H56.5068C57.2419 43.7759 57.9067 43.3453 58.2102 42.6755L59.2187 40.4471H67.821L68.8596 42.7788C69.1305 43.3856 69.7301 43.7759 70.3924 43.7759H73.9297L65.6284 25.1982H65.6259ZM60.7339 36.519L63.5186 30.1108L66.3032 36.519H60.7314H60.7339Z"
            fill="#F3703A"
          />
        </g>
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="66"
          id="filter0_d_46_592"
          width="1444"
          x="-2"
          y="0"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="2" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="1" />
          <feComposite className="fe-composite" in2="hardAlpha" operator="out" />
          <feColorMatrix
            className="fe-color-matrix"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_46_592" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_46_592"
            mode="normal"
            result="shape"
          />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_46_592"
          x1="41.3794"
          x2="45.3733"
          y1="18.0446"
          y2="18.0446"
        >
          <stop className="stop" stopColor="#F3903F" />
          <stop className="stop" offset="0.15" stopColor="#F7A83F" />
          <stop className="stop" offset="0.3" stopColor="#FABB3F" />
          <stop className="stop" offset="0.4" stopColor="#FCC240" />
        </linearGradient>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_46_592"
          x1="46.2392"
          x2="46.2406"
          y1="24.1815"
          y2="16.3348"
        >
          <stop className="stop" offset="0.02" stopColor="#FEC340" />
          <stop className="stop" offset="0.11" stopColor="#FAA741" />
          <stop className="stop" offset="0.22" stopColor="#F68E42" />
          <stop className="stop" offset="0.32" stopColor="#F47F43" />
          <stop className="stop" offset="0.4" stopColor="#F47A44" />
          <stop className="stop" offset="0.77" stopColor="#DA1C5C" />
        </linearGradient>
        <clipPath className="clip-path" id="clip0_46_592">
          <rect className="rect" fill="white" height="28" transform="translate(30 16)" width="83" />
        </clipPath>
      </defs>
    </svg>
  );
};

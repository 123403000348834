import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation1 } from "../../icons/Navigation1";
import styles from "./products_style.module.css";

export const Products = (): JSX.Element => {
  return (
    <div className={styles.products}>
      <div className={styles['overlap-wrapper']}>
        <div className={styles.overlap}>
          <img className={styles.dots} alt="Dots" src="/img/dots-4.png" />
          <img className={styles.img} alt="Dots" src="/img/dots-3.png" />
          <div className={styles.rectangle} />
          <img className={styles['dots-2']} alt="Dots" src="/img/dots2-1.png" />
          <div className={styles.div} />
          <div className={styles['text-wrapper']}>Product interrelationships</div>
          <div className={styles['rectangle-2']} />
          <Navigation1 className={styles['navigation-1']} />
          <a href='/'><div className={styles['text-wrapper-2']}>Home</div></a>
          <a href='/products'><div className={styles['text-wrapper-3']}>Products</div></a>
          <a href='/about'><div className={styles['text-wrapper-4']}>About</div></a>
          <a href='mailto:contact@atc-analytics.com'><div className={styles['text-wrapper-5']}>Contact</div></a>
          
          
          <p className={styles['get-more-from-your']}>
            Get More From Your <br />
            Go-To-Market Efforts
          </p>
          <div className={styles['text-wrapper-6']}>ATC Analytics</div>
          <Footer className={styles['footer-instance']} />
          <p className={styles.p}>
            Focus your sales and marketing efforts on companies with tech stacks that fit your solutions and point your
            team at prospects in an account.
          </p>
          <div className={styles['text-wrapper-7']}>Tech Stack Fit Finder</div>
          <p className={styles['text-wrapper-8']}>
            Improve the effectiveness of your sales team’s pitches and presentations by giving them the ability to
            assess the tech stacks of companies and determine the specific applications of software products across
            business initiatives.
          </p>
          <div className={styles['text-wrapper-9']}>Account Intelligence Engine</div>
          <div className={styles['overlap-group-wrapper']}>
            {/* <div className={styles['overlap-group']}>
              <div className={styles['text-wrapper-10']}>Try it now</div>
            </div> */}
          </div>
          <img className={styles['screenshot-fitfinder']} alt="Screenshot fitfinder" src="/img/screenshot-fitfinder-1.png" />
          <img className={styles.screenshot} alt="Screenshot" src="/img/screenshot-prospectintel-1.png" />
          <div className={styles['rectangle-3']} />
          <div className={styles['rectangle-4']} />
          <div className={styles['rectangle-5']} />
          <div className={styles.flexcontainer}>
            <p className={styles.text}>
              <span className={styles.span}>
                ATC’s Predictive Software Intelligence Products:
                <br />
              </span>
            </p>
            <div className={styles.text}>
              <span className={styles['text-wrapper-11']}>
                <ul className={styles['bullet-list']}>
                  <li>Identify companies with tech stacks that fit your ICP</li>
                  <li>Inform of top initiatives</li>
                  <li>Identify likely users of your products</li>
                </ul>
              </span>
            </div>
          </div>
          <div className={styles['div-wrapper']}>
            <div className={styles.overlap2}>
              <p className={styles['text-wrapper-12']}>For questions or a demo</p>
             

             <a href='mailto:contact@atc-analytics.com'><div className={styles['text-wrapper-13']}>CONTACT US</div></a>
              <img className={styles.vector} alt="Vector" src="/img/vector-1-2.svg" />
            </div>
          </div>
          {/* <div className={styles['group-2']}>
            <div className={styles['overlap-group']}>
              <div className={styles['text-wrapper-10']}>Try it now</div>
            </div>
          </div> */}
          {/* <div className={styles['vector-wrapper']}>
            <img className={styles['vector-2']} alt="Vector" src="/img/vector-2.svg" />
          </div> */}
          {/* <div className={styles['img-wrapper']}>
            <img className={styles['vector-2']} alt="Vector" src="/img/vector-3.svg" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Products } from "./screens/Product";
import { About } from "./screens/AboutUs";
import { Homepage } from "./screens/Homepage";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Homepage />,
  },
  {
    path: "/products",
    element: <Products />,
  },

  {
    path: "/about",
    element: <About />,
  },
  // {
  //   path: "/homepageu955u4631",
  //   element: <Homepage />,
  // },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};

import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation } from "../../components/Navigation";
import "./style.css";

export const Homepage = (): JSX.Element => {
  return (
    <div className="homepage">
      <div className="overlap-wrapper">
      <div className="div-2">
        <div className="overlap-3">
          <div className="overlap-4">
            <img className="grey" alt="Grey" src="/img/dots1-grey-1.png" />
            <div className="flexcontainer-2">
              <p className="text-2">
                <span className="text-wrapper-14">
                  We analyze and triangulate   <span className="text-wrapper-15">millions of data points</span>
        &nbsp; on development activities, usage trends, and product
                  interrelationships to forecast the adoption of products among:{" "}
                  <br />
                </span>
              </p>
              <p className="text-2">
                <span className="text-wrapper-15">
                  1 million companies
                  <br />
                </span>
              </p>
              <p className="text-2">
                <span className="text-wrapper-15">7,000 software products</span>
              </p>
            </div>
            <p className="ATC-s-insights-are">
              ATC's Insights Are Driving Better Results
            </p>
            <p className="text-wrapper-16">
              ATC Forecasts Software Product Adoption
            </p>
            <div className="rectangle-6" />
            <p className="the-aggregate">
              <span className="text-wrapper-17">
                The aggregate connections between software products{" "}
              </span>
              <span className="text-wrapper-18">increased nearly 3.5x</span>
            </p>
            {/* <div className="group-3">
              <div className="ellipse" />
              <p className="text-wrapper-19">1 dot = 5 million relationships</p>
            </div> */}
            <div className="rectangle-7" />
            <p className="the-average-number">
              <span className="text-wrapper-17">
                The average number of software products in use by a Fortune 1000
                company{" "}
              </span>
              <span className="text-wrapper-18">has doubled</span>
            </p>
            <div className="group-4">
              <div className="overlap-5">
                <img
                  className="vector-3"
                  alt="Vector"
                  src="/img/vector-1.svg"
                />
                <div className="group-5">
                  <div className="element-software-wrapper">
                    <p className="element-software">
                      <span className="text-wrapper-23">577</span>
                      <span className="text-wrapper-21">
                        <br />
                        software products
                      </span>
                    </p>
                  </div>
                </div>
                <div className="group-6">
                  <div className="overlap-6">
                    <p className="element-software-2">
                      <span className="text-wrapper-20">1,031</span>
                      <br />
                      <span className="text-wrapper-23">software products</span>
                    </p>
                  </div>
                </div>
                <div className="text-wrapper-25">2020</div>
                <div className="text-wrapper-26">2024</div>
              </div>
            </div>
            <div className="text-wrapper-27">2020</div>
            <div className="text-wrapper-28">2024</div>
            <div className="element-interrelations">
              <span className="text-wrapper-23">16M</span>
              <div className="text-wrapper-29">interrelations</div>
            </div>
            <div className="element-m-interrelations">
              <span className="text-wrapper-23">55M</span>
              <p className="text-wrapper-29">interrelations</p>
            </div>
            <div className="group-7">
              <div className="overlap-7">
                <div className="group-8">
                  <div className="group-9">
                    <div className="overlap-group-2">
                      <img className="line" alt="Line" src="/img/line-13.svg" />
                      <div className="ellipse-2" />
                    </div>
                  </div>
                  <div className="group-10">
                    <div className="overlap-group-2">
                      <img
                        className="line-2"
                        alt="Line"
                        src="/img/line-13-1.svg"
                      />
                      <div className="ellipse-2" />
                    </div>
                  </div>
                </div>
                <div className="group-11">
                  <div className="group-9">
                    <img
                      className="line-3"
                      alt="Line"
                      src="/img/line-13-2.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                  <div className="group-12">
                    <img
                      className="line-4"
                      alt="Line"
                      src="/img/line-13-3.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                </div>
                <div className="group-13">
                  <div className="group-9">
                    <img
                      className="line-5"
                      alt="Line"
                      src="/img/line-13-4.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                  <div className="group-12">
                    <img
                      className="line-6"
                      alt="Line"
                      src="/img/line-13-5.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                </div>
                <div className="group-14">
                  <div className="group-9">
                    <img
                      className="line-7"
                      alt="Line"
                      src="/img/line-13-6.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                  <div className="group-12">
                    <img
                      className="line-8"
                      alt="Line"
                      src="/img/line-13-7.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                </div>
                <div className="group-15">
                  <div className="group-9">
                    <img
                      className="line-9"
                      alt="Line"
                      src="/img/line-13-8.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                  <div className="group-10">
                    <img
                      className="line-10"
                      alt="Line"
                      src="/img/line-13-9.svg"
                    />
                    <div className="ellipse-2" />
                  </div>
                </div>
                <div className="ellipse-3" />
              </div>
            </div>
            <div className="group-16">
              <div className="overlap-8">
                <div className="group-17">
                  <div className="overlap-group-2">
                    <img
                      className="line"
                      alt="Line"
                      src="/img/line-13-10.svg"
                    />
                    <div className="ellipse-4" />
                  </div>
                </div>
                <div className="group-18">
                  <img
                    className="line-11"
                    alt="Line"
                    src="/img/line-13-12.svg"
                  />
                  <div className="ellipse-4" />
                </div>
                <div className="ellipse-5" />
              </div>
              <div className="group-19">
                <img className="line-12" alt="Line" src="/img/line-13-11.svg" />
                <div className="ellipse-4" />
              </div>
            </div>
            <div className="rectangle-8" />
            <div className="rectangle-9" />
            <div className="group-20">
              <div className="overlap-9">
                <div className="text-wrapper-31">Development activities</div>
                <div className="text-wrapper-32">
                  Product interrelationships
                </div>
                <div className="text-wrapper-33">Usage trends</div>
                <img
                  className="triangle"
                  alt="Triangle"
                  src="/img/triangle1-1.png"
                />
                <img className="line-13" alt="Line" src="/img/line-4-1.svg" />
                <img className="line-14" alt="Line" src="/img/line-6.svg" />
                <img className="line-15" alt="Line" src="/img/line-5.svg" />
              </div>
            </div>
            <img className="vector-4" alt="Vector" src="/img/vector-3-2.svg" />
          </div>
          <div className="overlap-10">
            <div className="overlap-11">
              <div className="overlap-12">
                <p className="the-time-for-new">
                  <span className="text-wrapper-17">
                    The time for new software product adoption to reach 50,000
                    developers{" "}
                  </span>
                  <span className="text-wrapper-18">accelerated 2.5x</span>
                </p>
                <div className="days-to">DAYS TO 50,000 DEVELOPERS</div>
                <p className="element-days">
                  <span className="text-wrapper-23">350</span>
                  <span className="text-wrapper-34">&nbsp;</span>
                  <span className="text-wrapper-35">days</span>
                </p>
                <div className="text-wrapper-36">2020</div>
                <div className="text-wrapper-37">2024</div>
                <div className="rectangle-10" />
                <div className="rectangle-11" />
                <img className="line-16" alt="Line" src="/img/line-4.svg" />
                <img className="line-17" alt="Line" src="/img/line-10.svg" />
              </div>
              <p className="element-days-2">
                <span className="text-wrapper-23">900</span>
                <span className="text-wrapper-34">&nbsp;</span>
                <span className="text-wrapper-35">days</span>
              </p>
              <img className="vector-5" alt="Vector" src="/img/image.svg" />
            </div>
            <div className="rectangle-12" />
          </div>
        </div>
        <div className="overlap-13">
          <img className="dots-grey" alt="Grey" src="/img/dots1-grey-2.png" />
          <div className="rectangle-13" />
          <div className="rectangle-14" />
          <div className="flexcontainer-3">
            <p className="span-wrapper">
              <span className="text-wrapper-23">Technology Companies</span>
            </p>
            <p className="span-wrapper">
              <span className="text-wrapper-38">
                Zero in on prospects with future technology priorities that best
                match their product offering.
              </span>
            </p>
          </div>
          <img className="line-18" alt="Line" src="/img/line-7.svg" />
          <Footer className="design-component-instance-node" />
          <div className="flexcontainer-4">
            <p className="span-wrapper">
              <span className="text-wrapper-23">Institutional Investors</span>
            </p>
            <p className="span-wrapper">
              <span className="text-wrapper-38">
                Identify software companies with inflecting product momentum
                before they are apparent to the market.
              </span>
            </p>
          </div>
          <img className="line-19" alt="Line" src="/img/line-7.svg" />
          <div className="rectangle-15" />
          <div className="flexcontainer-5">
            <p className="span-wrapper">
              <span className="text-wrapper-23">Major Corporations</span>
            </p>
            <p className="span-wrapper">
              <span className="text-wrapper-38">
                Understand rapidly evolving software options and the tech stacks
                of their competitors.
              </span>
            </p>
          </div>
          <img className="line-20" alt="Line" src="/img/line-7.svg" />
        </div>
        <div className="overlap-14">
          <div className="rectangle-16" />
          <img className="dots-3" alt="Dots" src="/img/dots1-1.png" />
          <div className="group-21">
            <img className="wave" alt="Wave" src="/img/wave1-1.png" />
            <img className="wave-2" alt="Wave" src="/img/wave1-2.png" />
            <img className="wave-3" alt="Wave" src="/img/wave1-2.png" />
          </div>
          <Navigation className="navigation-instance"></Navigation>
          <a href='/'><div className="text-wrapper-39">Home</div></a>
          <a href='/products'><div className="text-wrapper-40">Products</div></a>
          <a href='/about'><div className="text-wrapper-41">About</div></a>
          <a href='mailto:contact@atc-analytics.com'><div className="text-wrapper-42">Contact</div></a>
          
          <div className="text-wrapper-43">
            Predictive Software Intelligence
          </div>
          <p className="text-wrapper-44">
            Get Ahead of the Curve of the Rapidly Changing, Increasingly Complex
            Software Ecosystem
          </p>
          <div className="rectangle-17" />
          <p className="text-wrapper-45">
            The Complexity of the Software Ecosystem Is Increasing Dramatically
          </p>
          <div className="text-wrapper-46">ATC Analytics</div>
          <img className="polygon" alt="Polygon" src="/img/polygon-1.svg" />
        </div>
        </div>
      </div>
    </div>
  );
};
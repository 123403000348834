/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

interface Props {
  className: any;
}

export const Navigation = ({ className }: Props): JSX.Element => {
  return <a href='/'><img className={`navigation ${className}`} alt="Navigation" src="/img/navigation.svg" /></a>;
};

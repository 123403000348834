import React from "react";
import { Footer } from "../../components/Footer";
import { Navigation1 } from "../../icons/Navigation1";
import styles from "./aboutus_style.module.css";

export const About = (): JSX.Element => {
  return (
    <div className={styles.products}>
      <div className={styles['overlap-wrapper']}>
        <div className={styles.overlap}>
          <img className={styles.dots} alt="Dots" src="/img/dots-4.png" />
          <img className={styles.img} alt="Dots" src="/img/dots-3.png" />
          <div className={styles.rectangle} />
          <img className={styles['dots-2']} alt="Dots" src="/img/dots2-1.png" />
          <div className={styles.div} />
          <div className={styles['text-wrapper']}>Product interrelationships</div>
          <div className={styles['rectangle-2']} />
          
          <Navigation1 className={styles['navigation-1']} />
          <a href='/'><div className={styles['text-wrapper-2']}>Home</div></a>
          <a href='/products'><div className={styles['text-wrapper-3']}>Products</div></a>
          <a href='/about'><div className={styles['text-wrapper-4']}>About</div></a>
          
          
          <a href='mailto:contact@atc-analytics.com'><div className={styles['text-wrapper-5']}>Contact</div></a>
          
          <p className={styles['get-more-from-your']}>
          About ATC Analytics
          </p>
          
          <Footer className={styles['footer-instance']} />
          <p className={styles.p}>
          ATC's formation was born from its principal's obsession with understanding major shifts in the technology ecosystem and helping clients get "ahead of the curve" to capitalize on such shifts.  Over two decades, ATC's principals established Pacific Crest Securities as the leading independent technology research and investment bank relied on by the world's top institutional investors and technology companies.
          <br /><br />
          ATC Analytics was founded in 2020 after successfully merging Pacific Crest Securities into a major financial institution and has since developed its proprietary data driven Predictive Software Intelligence that is now being used by technology companies, institutional investors and major corporations to drive better results.
          </p>
    
 

          
          
          <img className={styles.screenshot} alt="Screenshot" src="/img/ecosystem_graphic.png" />
          
          
          <div className={styles['rectangle-5']} />
          <div className={styles.flexcontainer}>
            <p className={styles.text}>
              <span className={styles.span}>
              Two Decades Forecasting Significant Shifts and Performance in Technology
                <br />
              </span>
            </p>
          </div>
      
          <div className={styles['rectangle-6']}>
            <div className={styles['principals-box']}>
              <h2>ATC's Principals</h2>
              <div className={styles.principals}>
                <div className={styles.principal}>
                  <a href ="https://www.linkedin.com/in/scott-sandbo-09378b15/"><div className={styles['icon-linkedin']}></div></a>
                  <div>
                    <h3>Scott Sandbo</h3>
                    <p>CEO</p>
                  </div>
                </div>
                <div className={styles.principal}>
                  <a href="https://www.linkedin.com/in/stevelidberg/"><div className={styles['icon-linkedin']}></div></a>
                  <div>
                    <h3>Steve Lidberg</h3>
                    <p>President and COO</p>
                  </div>
                </div>
                <div className={styles.principal}>
                  <a href="https://www.linkedin.com/in/benwilson5/"><div className={styles['icon-linkedin']}></div></a>
                  <div>
                    <h3>Ben Wilson</h3>
                    <p>CTO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};
